import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdSearch, MdPhoto, MdRefresh, MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import { styles } from "../../../assets/styles/styles";
import Container from "@mui/material/Container";

import IconButton from "@mui/material/IconButton";
import AddSignals from "../../components/AddSignals";
import {
  deleteFSignal,
  getAllSignals,
  getAllSignalsSynthetics,
} from "../../store/actions/signals";
import { useDispatch, useSelector } from "react-redux";
import CustomBodyWithSpinner from "../../components/tables/CustomBodyWithSpinner";

import { MdEdit } from "react-icons/md";
import MUIDataTable from "mui-datatables";
import SignalsEditModal from "../../components/modals/SignalsEditModal";
import { useTheme } from "@mui/material";
import Swal from "sweetalert2";
import { Tab, Tabs } from "@mui/material";
import { startListeningToUsers } from "../../store/actions/users";

export default function SignalsView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSuperAdmin, isAlmighty } = useSelector((state) => state.auth);
  const { signals, binaries } = useSelector((state) => state.signals);
  const { users } = useSelector((state) => state.users);
  const [signalsFromDB, setSignalsFromDB] = useState([]);
  const isFirstUse = useRef(true);
  const [loading, setloading] = useState(true);
  const [loadingTable, setloadingTable] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [category, setcategory] = useState("forex");

  useEffect(() => {
    const unsubscribe = dispatch(startListeningToUsers());

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [dispatch]);

  const getSignalsFromDB = useCallback(() => {
    try {
      setloading(true);
      dispatch(getAllSignals());
      dispatch(getAllSignalsSynthetics());
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  }, [signals]);
  useEffect(() => {
    if (isFirstUse.current) {
      setloading(true);
      getSignalsFromDB();

      isFirstUse.current = false;
    } else if (signals?.length > 0 && binaries?.length > 0) {
      setloading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setloading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [binaries, signals, getSignalsFromDB]);

  const refresh = () => {
    setloading(true);
    setTimeout(() => {
      getSignalsFromDB();
      setloading(false);
    }, 1000);
  };
  const handleOpenEditModal = (item, cat) => {
    setSelectedItem(item);
    setEditModalOpen(true);
    setcategory(cat);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setEditModalOpen(false);
  };

  const BodyComponent = useMemo(
    () => (tableProps) => {
      if (!dataAvailable) {
        return (
          <CustomBodyWithSpinner loading={dataAvailable} {...tableProps} />
        );
      }

      return <CustomBodyWithSpinner loading={loading} {...tableProps} />;
    },
    [loading, dataAvailable]
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const columns = [
    {
      name: "currency",
      label: "Moneda",
    },
    {
      name: "picture",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
      },
      filter: false,
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired

          return formattedDate;
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },

    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = signals[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data, "forex");
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = signals[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar la señal?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteFSignal(data.id, "forex");
                  refresh();
                  Swal.fire("Señal eliminada", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar la señal", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              // disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const columnsBinaries = [
    {
      name: "currency",
      label: "Moneda",
    },
    {
      name: "picture",
      label: "Imagen",
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} alt="" width={"50px"} /> : "--";
        },
      },
      filter: false,
    },
    {
      name: "cdate",
      label: "Fecha",
      options: {
        customBodyRender: (value) => {
          const date = new Date(
            value.seconds * 1000 + value.nanoseconds / 1000000
          );
          const formattedDate = date.toLocaleDateString(); // Format the date as desired

          return formattedDate;
        },
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },

    {
      name: "edit",
      label: "Editar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = binaries[dataIndex];

          // console.log("schedules:", schedules);
          // console.log("dataIndex:", dataIndex);
          // console.log("schedule:", schedule);

          const handleEdit = () => {
            handleOpenEditModal(data, "binaries");
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.primary.secondary }}
              onClick={handleEdit}
            >
              <MdEdit />
            </IconButton>
          );
        },
      },
      filter: false,
    },
    {
      name: "delete",
      label: "Eliminar",
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const data = binaries[dataIndex];

          const handleDelete = () => {
            Swal.fire({
              title: "Alerta",
              text: "¿Está seguro que desea eliminar la señal?",
              icon: "warning",
              confirmButtonText: "Eliminar",
              showDenyButton: true,
              denyButtonText: "Volver",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await deleteFSignal(data.id, "binaries");
                  refresh();
                  Swal.fire("Señal eliminada", "", "success");
                } catch (error) {
                  console.error("Error deleting item:", error);
                  Swal.fire("Error al eliminar la señal", "", "error");
                }
              }
            });
          };

          return (
            <IconButton
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={handleDelete}
              // disabled={!isSuperAdmin && !isAlmighty}
            >
              <MdDelete />
            </IconButton>
          );
        },
      },
      filter: false,
    },
  ];
  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    onTableChange: () => setSignalsFromDB(signals),
    onRowsDelete: (rowsToDelete) => {
      // Show the delete confirmation alert using SweetAlert2
      Swal.fire({
        title: "Alerta",
        text: "¿Está seguro que desea eliminar la señal?",
        icon: "warning",
        confirmButtonText: "Eliminar",
        showDenyButton: true,
        denyButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Handle the delete operation for each row
            for (const row of rowsToDelete.data) {
              const dataIndex = row.index;
              const data = signals[dataIndex];

              // Use an asynchronous function to delete the item
              await deleteFSignal(data.id, dataIndex);
            }

            refresh();
            // Show success message after successful deletion
            Swal.fire("Señal eliminada", "", "success");

            // Trigger a refresh to update the data after deletion
          } catch (error) {
            console.error("Error deleting item:", error);
            // Show an error message if something goes wrong with the delete operation
            Swal.fire("Error al eliminar la señal", "", "error");
          }
        } else if (result.isDenied) {
          // Handle the "Volver" button action (if needed)
          console.log("Volver button clicked");
        }
      });

      // Prevent the default behavior of the MUIDataTable
      return false;
    },
  };

  return (
    <div id="tab-content">
      <Container maxWidth="xl" sx={{ p: "0px !important" }}>
        <div className="divider"></div>

        <Box sx={{ mt: 6 }}>
          <AddSignals refresh={refresh} category={"forex"} users={users} />
        </Box>
        <Box sx={styles.cardStyle} className="content theme-card">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.primary.main },
            }}
          >
            <Tab
              label="Forex"
              style={
                tabValue === 0
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />
            <Tab
              label="Sintéticos"
              style={
                tabValue === 1
                  ? { color: theme.palette.primary.main }
                  : { color: "white" }
              }
            />

            <div className="ml-auto mr-5  ">
              <IconButton color="success" onClick={() => refresh()}>
                <MdRefresh size={24} />
              </IconButton>
            </div>
          </Tabs>
          <div className="table-wrapper  center mt-4">
            {tabValue === 0 && (
              <MUIDataTable
                data={signals}
                columns={columns}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
            {tabValue === 1 && (
              <MUIDataTable
                data={binaries}
                columns={columnsBinaries}
                options={options}
                components={{ TableBody: BodyComponent }}
              />
            )}
          </div>
        </Box>
        {selectedItem && (
          <SignalsEditModal
            signal={selectedItem}
            open={editModalOpen}
            onClose={handleCloseEditModal}
            refresh={refresh}
            category={category}
            users={users}
          />
        )}
      </Container>
    </div>
  );
}
