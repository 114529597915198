import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";

import { styles } from "../../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { editSynthetics } from "../../store/actions/items";

import {
  homeItems,
  levelOptions,
  membershipOptions,
} from "../../utils/formData";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getExpiredStatus } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

const SyntheticsEditModal = ({ refresh, open, onClose, data, theme }) => {
  const [value, setValue] = useState("community");
  const [files, setFiles] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("basic");
  const [selectedMembership, setSelectedMembership] = useState("members");
  const [expired, setexpired] = useState(false);

  useEffect(() => {
    if (data.isMembersOnly && data.isGoldPlan) {
      setSelectedMembership("goldPlan");
    }
    if (data.isMembersOnly && !data.isGoldPlan) {
      setSelectedMembership("members");
    }
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const selectedFields = useMemo(() => {
    const selectedItem = homeItems.find((item) => item.value === value);
    return selectedItem ? selectedItem.fields : [];
  }, [value]);

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const handleMembershipChange = (event) => {
    setSelectedMembership(event.target.value);
  };
  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    // Remove the trailing "&" from the videoURL if it exists
    const cleanedVideoURL = data.videoURL
      ? data.videoURL.replace(/&$/, "")
      : "";

    formik.setValues({
      contentType: value,
      name: data.name ?? "",
      category: data.category ?? "",
      clickMe: data.clickMe ?? "",
      section: data.section ?? "",
      level: data.level ?? "",
      streamURL: data.streamURL ?? "",
      isMembersOnly: data.isMembersOnly ?? false,
      isGoldPlan: data.isGoldPlan ?? false,
      type: data.type ?? "",
      description: data.description ?? "",
      videoURL: cleanedVideoURL,
      orderNumber: data.orderNumber ?? 0,
    });
  }, [data]);

  const formik = useFormik({
    initialValues: {
      contentType: value,
      name: data.name ?? "",
      category: data.category ?? "",
      clickMe: data.clickMe ?? "",

      section: data.section ?? "",
      level: data.level ?? "",
      streamURL: data.streamURL ?? "",
      isMembersOnly: data.isMembersOnly ?? false,
      isGoldPlan: data.isGoldPlan ?? false,
      type: data.type ?? "",
      description: data.description ?? "",
      videoURL: data.videoURL ?? "",
      orderNumber: data.orderNumber ?? 0,
    },
    enableReinitialize: true,
    validationSchema: () => {
      const validationSchema = yup.object({
        name: yup.string("Enter the name").required("Required field"),
        // Add validation rules for common fields
      });

      return validationSchema;
    },
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const submitForm = async (values) => {
    let members = false;
    let goldPlan = false;
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    if (selectedMembership === "members") {
      members = true;
      goldPlan = false;
    } else if (selectedMembership === "goldPlan") {
      members = true;
      goldPlan = true; // Set goldPlan to true for the "goldPlan" membership
    }

    const updatedData = {
      name: values.name,
      description: values.description,
      streamURL: values.streamURL,
      isMembersOnly: members,
      isGoldPlan: goldPlan,
      section: values.section,
      level: values.level,
      videoURL: data.videoURL.includes("youtube")
        ? data.videoURL + "&"
        : data.videoURL,
      cdate: new Date(),
      orderNumber: values.orderNumber,
    };
    console.log(updatedData);
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Upload to Firebase Storage
        // const file = files[0];
        // const storageRef = storage.ref();
        // const imageRef = storageRef.child(
        //   `images/items/${value === "pro" ? "pro" : "lab"}/${file.name}`
        // );
        // await imageRef.put(file);

        // const imageURL = await imageRef.getDownloadURL();

        // values.imageURL = imageURL;

        try {
          await editSynthetics(updatedData, data.id);
          MySwal.fire("Item Guardado!", "", "success");
          refresh();
          formik.resetForm();
          onClose();
        } catch (error) {
          console.log(error);
          MySwal.fire("Hubo un error al guardar el item", "", "error");
        }
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1001 }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,
          minWidth: "35vw",
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Item
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={11}
            sx={[styles.cardStyle, { minHeight: "65vh", width: "100%", p: 2 }]}
          >
            <Box
              component={"form"}
              sx={[styles.innerCardStyle, { minHeight: "65vh" }]}
              onSubmit={formik.handleSubmit}
            >
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="section"
                name="section"
                select
                label="Sección"
                onChange={formik.handleChange}
                value={formik.values.section} // Use formik value
              >
                {homeItems.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="level"
                name="level"
                select
                label="Nivel"
                value={formik.values.level} // Use formik value
                onChange={formik.handleChange}
              >
                {levelOptions.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );

                  return null;
                })}
              </TextField>

              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                label="Descripción"
                type="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="videoURL"
                name="videoURL"
                label="URL de Video"
                value={formik.values.videoURL}
                onChange={formik.handleChange}
                error={
                  formik.touched.videoURL && Boolean(formik.errors.videoURL)
                }
                helperText={formik.touched.videoURL && formik.errors.videoURL}
              />
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="streamURL"
                name="streamURL"
                label="URL de transmisión"
                value={formik.values.streamURL}
                onChange={formik.handleChange}
                error={
                  formik.touched.streamURL && Boolean(formik.errors.streamURL)
                }
                helperText={formik.touched.streamURL && formik.errors.streamURL}
              />
              <TextField
                variant="standard"
                fullWidth
                type="number"
                id="orderNumber"
                name="orderNumber"
                label="Numero Orden"
                value={formik.values.orderNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.orderNumber &&
                  Boolean(formik.errors.orderNumber)
                }
                helperText={
                  formik.touched.orderNumber && formik.errors.orderNumber
                }
              />
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="membership"
                name="membership"
                select
                label="Membresía"
                value={selectedMembership}
                onChange={handleMembershipChange}
              >
                {membershipOptions.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </TextField>

              {/* <Box item md={6} sx={styles.uploadBoxStyle}>
              {files.length === 0 ? (
                <div className=" center m-auto">
                  <Dragndrop onDrop={onDrop} accept={"image/*"} maxFiles={1} />
                </div>
              ) : (
                <Preview files={files} removeAll={removeAll} />
              )}
            </Box> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "auto",
                  marginBottom: "5px",
                }}
              >
                <Button
                  className="w-50"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Editar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SyntheticsEditModal;
