import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import { navbarStyles } from "../../assets/styles/navbarStyles";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import MenuFlag from "./menu/MenuFlag";
import ProfileDropdownMenu from "./menu/ProfileDropdownMenu";
import { ADMINVERSION } from "../utils/info";

const TopNavbar = ({ drawerWidth, setOpen, open, handleDrawerOpen }) => {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const location = useLocation();

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [titleApp, setTitleApp] = useState("Inicio");
  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setTitleApp("Inicio");
        break;
      case "/items":
        setTitleApp("Contenido Home");
        break;
      case "/services":
        setTitleApp("Videos Home");
        break;
      case "/synthetics":
        setTitleApp("Sintéticos");
        break;
      case "/cryptos":
        setTitleApp("Cryptos");
        break;
      case "/forex":
        setTitleApp("Forex");
        break;
      case "/community":
        setTitleApp("Comunidad");
        break;
      case "/academy":
        setTitleApp("Banners Academia");
        break;
      case "/notifications":
        setTitleApp("Notificaciones");
        break;

      case "/schedules":
        setTitleApp("Agenda");
        break;
      case "/schedules/sessions":
        setTitleApp("Sesiones");
        break;
      case "/schedules/masterclass":
        setTitleApp("Masterclass");
        break;
      case "/users":
        setTitleApp("Usuarios");
        break;
      case "/admins":
        setTitleApp("Administradores");
        break;

      case "/signals":
        setTitleApp("Señales");
        break;

      default:
        setTitleApp("Inicio");
        break;
    }
  }, [location, setTitleApp]);

  return (
    <AppBar position="fixed" open={open}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            component="div"
            color="primary"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <h2>{titleApp}</h2>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            color="primary"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <h2>{titleApp}</h2>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
            {/* <MenuFlag/>  */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexGrow: 0,
              marginLeft: "auto",
              flexDirection: "row",
            }}
          >
            <Box mr={2}>
              <Typography variant="body1">{ADMINVERSION}</Typography>
            </Box>
            <ProfileDropdownMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopNavbar;
