import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import Login from "./../views/auth/Login";
import SidebarLayout from "./../components/SidebarLayout";
import { PrivateRoute } from "./PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase/firebase-config";
import {
  getAdminStatus,
  logoutUser,
  setIsLoadingAuth,
  signInWithEmail,
} from "../store/actions/auth";
import { Typography } from "@mui/material";
import ItemsView from "./../views/ItemsView";

import NotificationsView from "../views/NotificationsView";

import SignalsView from "../views/signals/SignalsView";
import UsersView from "./../views/UsersView";
import WeeklyNotificationsView from "./../views/WeeKlyNotificationsView";
import SyntheticsView from "../views/trading/SyntheticsView";
import ForexView from "../views/trading/ForexView";
import CryptosView from "../views/trading/CryptosView";
import CommunityView from "../views/community/CommunityView";
import AcademyBannersView from "../views/AcademyBannersView";
import ServicesView from "../views/ServicesView";
import SessionsView from "../views/schedules/SessionsView";
import MasterclassView from "../views/schedules/MasterclassView";
import AdminsView from "../views/AdminsView";

function AppRoutes() {
  const dispatch = useDispatch();
  const history = useLocation();
  const { loggedIn, isLoadingAuth } = useSelector((state) => state.auth);
  const lastUrl = localStorage.getItem("lastUrl") || "/home";

  const [isSuper, setisSuper] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  useEffect(() => {
    dispatch(setIsLoadingAuth(true));
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const metadata = await user.getIdTokenResult();
        // Check if the user's email is allowed

        if (
          metadata.claims.superadmin ||
          metadata.claims.admin ||
          metadata.claims.almighty ||
          metadata.claims.moderator
        ) {
          dispatch(signInWithEmail(user.toJSON(), metadata.claims));
          sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
          const status = await getAdminStatus(user.email);

          if (
            status.adminClaim === "superadmin" ||
            status.adminClaim === "almighty"
          ) {
            setisSuper(true);
          } else if (metadata.claims.moderator) {
            setIsModerator(true);
          }
          // console.log(metadata.claims);
        } else {
          sessionStorage.removeItem("user");
          dispatch(logoutUser());
        }

        dispatch(setIsLoadingAuth(false));
      } else {
        console.log("no hay");
        sessionStorage.removeItem("user");
        dispatch(logoutUser());
        dispatch(setIsLoadingAuth(false));
      }
    });
  }, [dispatch]);

  // useEffect(() => {
  //   // Your existing code...

  //   // Use onIdTokenChanged to detect changes in the user's token
  //   const unsubscribe = auth.onIdTokenChanged(async (currentUser) => {
  //     if (!currentUser) {
  //       // The user has logged out or the token is no longer valid
  //       sessionStorage.removeItem("user");
  //       dispatch(logoutUser());
  //     }
  //   });

  //   // Cleanup function to unsubscribe the listener when the component is unmounted
  //   return () => unsubscribe();
  // }, [dispatch]);

  // useEffect(() => {

  //   const status = await getAdminStatus(user.email);
  //   if (
  //     status.adminClaim === "superadmin" ||
  //     status.adminClaim === "almighty"
  //   ) {
  //     setisSuper(true);
  //   }
  //   if (status.adminClaim === "moderator") {
  //     setIsModerator(true);
  //   }
  // }, [third])

  // if (
  //   metadata.claims.superadmin ||
  //   metadata.claims.admin ||
  //   metadata.claims.almighty
  // ) {
  //   dispatch(signInWithEmail(user.toJSON(), metadata.claims));
  //   sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
  // } else {
  //   sessionStorage.removeItem("user");
  //   dispatch(logoutUser());
  // }

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("lastUrl", history.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history]);

  if (isLoadingAuth) {
    return (
      <div id="login">
        <div className="login-wrapper ">
          <div className="align">
            <Typography component="h1" variant="h5">
              Cargando ...
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<SidebarLayout />}>
          <Route
            path=""
            element={
              loggedIn ? (
                <Navigate replace to={lastUrl} />
              ) : (
                <Navigate to="/auth/login" />
              )
            }
          />
          {/* ERROR PAGE <Route path="*" element={<Dashboard />} /> */}
          <Route
            path="/*"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <Routes>
                  <Route
                    path="/auth/login"
                    element={
                      <Navigate
                        replace
                        to={lastUrl === "/auth/login" ? "/home" : lastUrl}
                      />
                    }
                  />
                  <Route index path="/home" element={<Dashboard />} />
                  <Route path="/items" element={<ItemsView />} />
                  <Route path="/academy" element={<AcademyBannersView />} />
                  <Route path="/services" element={<ServicesView />} />
                  <Route path="/community" element={<CommunityView />} />
                  <Route path="/synthetics" element={<SyntheticsView />} />
                  <Route path="/forex" element={<ForexView />} />
                  <Route path="/cryptos" element={<CryptosView />} />
                  <Route
                    path="/notifications"
                    element={<NotificationsView />}
                  />
                  <Route
                    path="/weekly-notifications"
                    element={<WeeklyNotificationsView />}
                  />
                  <Route
                    path="/schedules/masterclass"
                    element={<MasterclassView />}
                  />
                  <Route
                    path="/schedules/sessions"
                    element={<SessionsView />}
                  />
                  {!isModerator && (
                    <Route path="/users" element={<UsersView />} />
                  )}
                  {isSuper && <Route path="/admins" element={<AdminsView />} />}
                  {(isSuper || isModerator) && (
                    <Route path="/signals" element={<SignalsView />} />
                  )}
                </Routes>
              </PrivateRoute>
            }
          />
        </Route>

        {!loggedIn ? (
          <Route Route path="/auth">
            <Route path="" element={<Navigate replace to="login" />} />
            <Route path="login" element={<Login />} />
          </Route>
        ) : null}
      </Routes>
    </>
  );
}

export default AppRoutes;
