import jwtDecode from "jwt-decode";

import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  postPushNotificationActive,
  postPushNotificationAll,
  postPushNotificationGold,
} from "../store/actions/notifications";
import { useSelector } from "react-redux";
import { getExpiredStatus, refreshToken } from "../store/actions/auth";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { MdSignalCellular1Bar } from "react-icons/md";

const MySwal = withReactContent(Swal);

const AddNotifications = (props) => {
  const { idToken } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(false);
  const [expired, setexpired] = useState(false);
  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  //Formik
  const validationSchema = yup.object({
    title: yup.string("Ingrese el titulo").required("Campo Obligatorio"),
    text: yup
      .string("Ingrese el texto de la notificación")
      .required("Campo Obligatorio"),
    // itemId: yup.string("Ingrese un Id de item").required("Campo Obligatorio"),
  });
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      text: "",
      isActive: false,
      isGoldPlan: false,
      level: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });

  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);
  const SubmitForm = (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }
    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere enviar esta notificación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      const data = {
        title: values.title ?? "",
        text: values.text ?? "",
      };

      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });
        const selectedTier = values.level;
        try {
          let success = false;

          if (isValidToken(idToken)) {
            switch (selectedTier) {
              case 3:
                success = (await postPushNotificationGold(data, idToken)).ok;
                break;
              case 2:
                success =
                  (await postPushNotificationActive(data, idToken)).ok &&
                  (await postPushNotificationGold(data, idToken)).ok;
                break;
              default:
                success = (await postPushNotificationAll(data, idToken)).ok;
                break;
            }
          } else {
            console.log("Refreshing token");
            const newToken = await refreshToken();

            switch (selectedTier) {
              case 3:
                // Send to gold plan users
                success = (await postPushNotificationGold(data, newToken)).ok;
                break;
              case 2:
                // Send to active and gold plan users
                success =
                  (await postPushNotificationActive(data, newToken)).ok &&
                  (await postPushNotificationGold(data, newToken)).ok;
                break;
              default:
                // Send to all users
                success = (await postPushNotificationAll(data, newToken)).ok;
                break;
            }
          }

          if (success) {
            MySwal.fire("Notificación Enviada!", "", "success");
          } else {
            MySwal.fire("Hubo un error al enviar la notificación", "", "error");
          }
        } catch (error) {
          console.error("Error:", error);
          MySwal.fire("Hubo un error al enviar la notificación", "", "error");
        }
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <Box sx={styles.cardStyle} mb={4}>
      <form onSubmit={formik.handleSubmit} className="m-auto p-2">
        <Typography sx={styles.cardTitleStyle} variant="h6" className="center">
          Agregar Notificación
        </Typography>
        <Box sx={styles.innerCardStyle}>
          <Box className="">
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="title"
              name="title"
              label="Nombre"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="text"
              name="text"
              label="Descripción"
              type="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
            {/* <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="itemId"
              name="itemId"
              label="Id de Item"
              type="text"
              value={formik.values.itemId}
              onChange={formik.handleChange}
              error={formik.touched.itemId && Boolean(formik.errors.itemId)}
              helperText={formik.touched.itemId && formik.errors.itemId}
            /> */}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              id="level"
              name="level"
              select
              label="Nivel"
              value={formik.values.level}
              onChange={formik.handleChange}
              error={formik.touched.level && Boolean(formik.errors.level)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                },
                PopperProps: {
                  disablePortal: true,
                  placement: "bottom-start", // Adjust the placement as needed
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSignalCellular1Bar />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {tiers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "20px",
              marginBottom: "5px",
            }}
          >
            <Button
              className="w-50"
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Crear
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddNotifications;
