import jwtDecode from "jwt-decode";

import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MdNotificationImportant, MdSignalCellular1Bar } from "react-icons/md";
import { addSignal, postPushNotificationAll } from "../store/actions/signals";
import Dragndrop from "./dragndrop/Dragndrop";

import { storage } from "../../firebase/firebase-config";
import { getExpiredStatus, refreshToken } from "../store/actions/auth";
import { useSelector } from "react-redux";
import Preview from "./dragndrop/Preview";
import { Checkbox, FormControlLabel } from "@mui/material";

const MySwal = withReactContent(Swal);

const signalType = [
  {
    value: "forex",
    label: "Forex",
  },
  {
    value: "binaries",
    label: "Sintéticos",
  },
];

const tiers = [
  {
    value: 2,
    label: "Basic",
  },
];
const tiersLuxe = [
  {
    value: 3,
    label: "Luxe",
  },
];
const signalMode = [
  {
    value: "buy",
    label: "Buy",
  },
  {
    value: "sell",
    label: "Sell",
  },
  {
    value: "buyMarket",
    label: "Buy Market",
  },
  {
    value: "sellMarket",
    label: "Sell Market",
  },
  {
    value: "buyLimit",
    label: "Buy Limit",
  },
  {
    value: "sellLimit",
    label: "Sell Limit",
  },
  {
    value: "buyStop",
    label: "Buy Stop",
  },
  {
    value: "sellStop",
    label: "Sell Stop",
  },
];
const status = [
  {
    value: "active",
    label: "Activa",
  },

  {
    value: "won",
    label: "Ganada",
  },
  {
    value: "lost",
    label: "Perdida",
  },
  {
    value: "closed",
    label: "Cerrada",
  },
  {
    value: "discarded",
    label: "Descartada",
  },
];
const AddSignals = ({ refresh, users }) => {
  const { idToken } = useSelector((state) => state.auth);
  const [images, setImages] = useState([]);

  const [files, setFiles] = useState([]);
  const [preloadedUsers, setPreloadedUsers] = useState([]);

  const [expired, setexpired] = useState(false);
  useEffect(() => {
    // Clear added items when the component is loaded
    setFiles([]);
    setImages([]); // Clear the added files array
  }, []);

  const validationSchema = yup.object({
    type: yup.string("Ingrese un tipo de señal").required("Campo Obligatorio"),
    currency: yup.string("Ingrese una moneda").required("Campo Obligatorio"),
    price: yup.string("Ingrese un precio").required("Campo Obligatorio"),
    eta: yup.string("Ingrese un tiempo estimado").required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      signalType: "forex",
      alias: "",
      type: "",
      description: "",
      tier: 2,
      currency: "",
      entryPrice: "",
      eta: "",
      signalMode: "",
      stopLoss: "",
      tp1: "",
      tp2: "",
      tp3: "",
      tp4: "",
      tp5: "",
      tp6: "",
      status: "",
      picture: "",
      imageDescription: "",
      tp1Reached: false,
      tp2Reached: false,
      tp3Reached: false,
      tp4Reached: false,
      tp5Reached: false,
      tp6Reached: false,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  useEffect(() => {
    if (formik.values.signalType === "forex") {
      formik.setFieldValue("tier", 3);
    }
    if (formik.values.signalType === "binaries") {
      formik.setFieldValue("tier", 2);
    }
  }, [formik.values.signalType]);
  const preLoadUserList = async (selectedTier) => {
    let filteredUsers;

    switch (selectedTier) {
      case 2:
        filteredUsers = users.filter(
          (user) => user.isActive && user.pushToken !== undefined
        );
        break;
      case 3:
        filteredUsers = users.filter(
          (user) => user.isGoldPlan && user.pushToken !== undefined
        );
        break;
      default:
        filteredUsers = [];
    }

    const pTokens = filteredUsers.map((user) => user.pushToken).filter(Boolean);
    // console.log(filteredUsers);
    setPreloadedUsers(pTokens);
  };

  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);
  const SubmitForm = async (values) => {
    let loadingTimer;

    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Esta seguro que quiere agregar este señal?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (images.length > 0) {
        const timestamp = Date.now();

        const file = images[0];
        const storageRef = storage.ref();
        const fileNameWithDate = `${file.name}_${timestamp}`;
        // Upload the new image inside the course folder
        const imageRef = storageRef.child(
          `images/items/signals/${fileNameWithDate}`
        );
        await imageRef.put(file);
        values.picture = await imageRef.getDownloadURL();
      } else {
        // If no new images are selected, keep the original imageURL
        values.picture = "";
      }
      if (values.tier === 2) {
        values.isMembersOnly = true;
        values.isGoldPlan = false;
      } else if (values.tier === 3) {
        values.isMembersOnly = true;
        values.isGoldPlan = true;
      }
      const signalData = {
        alias: values.alias,
        type: values.type,
        currency: values.currency,
        entryPrice: values.entryPrice,
        eta: values.eta,
        stopLoss: values.stopLoss,
        tp1: values.tp1,
        tp2: values.tp2,
        tp3: values.tp3,
        tp4: values.tp4,
        tp5: values.tp5,
        tp6: values.tp6,
        isGoldPlan: values.isGoldPlan,
        isMembersOnly: values.isMembersOnly,
        status: values.status === "active" ? "" : values.status,
        cdate: new Date(),
        picture: values.picture,
        tier: values.tier,
        tp1Reached: values.tp1Reached,
        tp2Reached: values.tp2Reached,
        tp3Reached: values.tp3Reached,
        tp4Reached: values.tp4Reached,
        tp5Reached: values.tp5Reached,
        tp6Reached: values.tp6Reached,
      };
      const getStatusSignal = () => {
        switch (values.status) {
          case "active":
            switch (values.type) {
              case "buy":
                return "¡COMPRAR!";
              case "sell":
                return "¡VENDER!";
              case "":
                return "ACTIVA";
              default:
                return "ACTIVA";
            }
          case "pending":
            return "PENDIENTE";
          case "won":
            return "GANADA";
          case "lost":
            return "PERDIDA";
          case "discarded":
            return "DESCARTADA";
          case "break":
            return "BREAK EVEN";
          case "cancelled":
            return "CANCELADA";
          case "closed":
            return "CERRADA";
          case "custom":
            return "";
          default:
            return "PENDIENTE";
        }
      };

      const selectedTier = values.tier;
      const data = {
        title: `¡Nueva Señal! ${values.currency}`,
        text: getStatusSignal(),

        type: values.signalType === "forex" ? "forex" : "sinthethics",
        audience: selectedTier === 2 ? "basic" : "luxe",
        pushTokens: preloadedUsers,
      };
      console.log(data);
      if (result.isConfirmed) {
        const loadingSwal = MySwal.fire({
          icon: "warning",
          title: `Enviando a  usuarios de ${
            values.tier === 2 ? "Basic y Luxe" : "Luxe"
          }`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            // Set a timer to close the Swal loading after 3.5 seconds
            loadingTimer = setTimeout(() => {
              MySwal.close();
              MySwal.fire("Notificación Enviada!", "", "success");
            }, 4100);
          },
          showConfirmButton: false,
        });
        try {
          await addSignal(signalData, values.signalType);
          const tokenToUse = isValidToken(idToken)
            ? idToken
            : await refreshToken();
          // const apiCallStartTime = performance.now();

          await postPushNotificationAll(data, tokenToUse);
          clearTimeout(loadingTimer);
          MySwal.fire("Señal Enviada!", "", "success");
          loadingSwal.close();
          refresh();
          resetForm();
        } catch (error) {
          console.error(error);
          loadingSwal.close();
          MySwal.fire({
            title: "Error",
            text: "Ocurrió un error al enviar la señal. Por favor, inténtelo de nuevo más tarde.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };
  //Dropzone
  const resetForm = () => {
    formik.resetForm();
    setFiles([]);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);

    URL.revokeObjectURL(images[0].preview);
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item md={8} xl={6} lg={6} sm={10} mb={6} xs={12}>
        <Box sx={styles.cardStyle}>
          <form onSubmit={formik.handleSubmit} className="m-auto p-2">
            <Typography
              sx={styles.cardTitleStyle}
              variant="h6"
              className="center"
            >
              Agregar Señales
            </Typography>

            <Box sx={styles.innerCardStyle}>
              <Grid container justifyContent={"space-between"} mt={1}>
                <TextField
                  fullWidth
                  id="signalType"
                  name="signalType"
                  select
                  label="Seleccione"
                  value={formik.values.signalType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.signalType &&
                    Boolean(formik.errors.signalType)
                  }
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                    },
                    PopperProps: {
                      disablePortal: true,
                      placement: "bottom-start", // Adjust the placement as needed
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdSignalCellular1Bar />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                >
                  {signalType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Grid item md={12} xs={12}>
                  {formik.values.signalType === "forex" ? (
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      label="Seleccione"
                      id="tier"
                      name="tier"
                      select
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      helperText="Escoja la sección que desea cambiar"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdNotificationImportant />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {tiersLuxe.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      label="Seleccione"
                      id="tier"
                      name="tier"
                      select
                      value={formik.values.tier}
                      onChange={formik.handleChange}
                      helperText="Escoja la sección que desea cambiar"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          disableScrollLock: true,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MdNotificationImportant />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {tiers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",

                    marginBottom: "5px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                      width: "100%",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      sx={{ width: { md: "50%", xs: "100%" } }}
                      onClick={() => preLoadUserList(formik.values.tier)}
                    >
                      Precargar lista
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={12} container justifyContent={"center"}>
                  {preloadedUsers.length > 0 && (
                    <Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          sx={{
                            fontSize: "1rem !important",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Audiencia: {"  "}
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: "700 !important",
                            fontSize: "1rem !important",
                            ml: 0.51,
                          }}
                        >
                          {preloadedUsers.length}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box className="mt-4">
                <TextField
                  fullWidth
                  id="type"
                  name="type"
                  select
                  label="Tipo"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                    },
                    PopperProps: {
                      disablePortal: true,
                      placement: "bottom-start", // Adjust the placement as needed
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdSignalCellular1Bar />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                >
                  {signalMode.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="status"
                  name="status"
                  select
                  label="Status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                    },
                    PopperProps: {
                      disablePortal: true,
                      placement: "bottom-start",
                    },
                  }}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="alias"
                  name="alias"
                  label="Nombre"
                  value={formik.values.alias}
                  onChange={formik.handleChange}
                  error={formik.touched.alias && Boolean(formik.errors.alias)}
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="currency"
                  name="currency"
                  label="Moneda"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.currency && Boolean(formik.errors.currency)
                  }
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="entryPrice"
                  name="entryPrice"
                  label="Precio de Entrada"
                  value={formik.values.entryPrice}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.entryPrice &&
                    Boolean(formik.errors.entryPrice)
                  }
                />
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="stopLoss"
                  name="stopLoss"
                  label="Stop Loss"
                  type="text"
                  value={formik.values.stopLoss}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.stopLoss && Boolean(formik.errors.stopLoss)
                  }
                />
                <Grid container justifyContent={"space-between"}>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp1"
                      name="tp1"
                      label="tp1"
                      type="text"
                      value={formik.values.tp1}
                      onChange={formik.handleChange}
                      error={formik.touched.tp4 && Boolean(formik.errors.tp1)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp1Reached}
                          onChange={formik.handleChange}
                          name="tp1Reached"
                          color="primary"
                        />
                      }
                      label="tp1 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp2"
                      name="tp2"
                      label="tp2"
                      type="text"
                      value={formik.values.tp2}
                      onChange={formik.handleChange}
                      error={formik.touched.tp3 && Boolean(formik.errors.tp2)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp2Reached}
                          onChange={formik.handleChange}
                          name="tp2Reached"
                          color="primary"
                        />
                      }
                      label="tp2 Alc"
                    />
                  </Grid>

                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp3"
                      name="tp3"
                      label="tp3"
                      type="text"
                      value={formik.values.tp3}
                      onChange={formik.handleChange}
                      error={formik.touched.tp3 && Boolean(formik.errors.tp3)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp3Reached}
                          onChange={formik.handleChange}
                          name="tp3Reached"
                          color="primary"
                        />
                      }
                      label="tp3 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp4"
                      name="tp4"
                      label="tp4"
                      type="text"
                      value={formik.values.tp4}
                      onChange={formik.handleChange}
                      error={formik.touched.tp4 && Boolean(formik.errors.tp4)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp4Reached}
                          onChange={formik.handleChange}
                          name="tp4Reached"
                          color="primary"
                        />
                      }
                      label="tp4 Alc"
                    />
                  </Grid>

                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp5"
                      name="tp5"
                      label="tp5"
                      type="text"
                      value={formik.values.tp5}
                      onChange={formik.handleChange}
                      error={formik.touched.tp5 && Boolean(formik.errors.tp5)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp5Reached}
                          onChange={formik.handleChange}
                          name="tp5Reached"
                          color="primary"
                        />
                      }
                      label="tp5 Alc"
                    />
                  </Grid>
                  <Grid item md={3} xs={4.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="tp6"
                      name="tp6"
                      label="tp6"
                      type="text"
                      value={formik.values.tp6}
                      onChange={formik.handleChange}
                      error={formik.touched.tp6 && Boolean(formik.errors.tp6)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.tp6Reached}
                          onChange={formik.handleChange}
                          name="tp6Reached"
                          color="primary"
                        />
                      }
                      label="tp6 Alc"
                    />
                  </Grid>

                  <Grid item md={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción"
                      type="text"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                    />
                  </Grid>
                  <Grid item md={5.5}>
                    <TextField
                      margin="dense"
                      variant="standard"
                      fullWidth
                      id="eta"
                      name="eta"
                      label="Tiempo Estimado"
                      type="text"
                      value={formik.values.eta}
                      onChange={formik.handleChange}
                      error={formik.touched.eta && Boolean(formik.errors.eta)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box item md={6} mt={2} sx={styles.uploadBoxStyle}>
                {images.length === 0 ? (
                  <div className=" center m-auto">
                    <Dragndrop
                      onDrop={onDrop}
                      accept={"image/*"}
                      maxFiles={1}
                    />
                  </div>
                ) : (
                  <Preview files={images} removeAll={removeAll} />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  mt: 5,
                  marginBottom: "5px",
                }}
              >
                <Button
                  className="w-50"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={preloadedUsers.length === 0}
                >
                  Crear
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddSignals;
