import { db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL, API_URL } from "../../variables/globals";
export const SET_SIGNALS = "SET_SIGNALS";
export const SET_BINARIES = "SET_BINARIES";
export const SET_IS_LOADING = "SET_IS_LOADING";

export const getAllSignals = () => {
  return (dispatch) => {
    const signals = [];
    dispatch(isLoading(true));
    db.collection("signals")
      .doc("forex")
      .collection("items")
      .get()
      .then(async (itemsFromFs) => {
        console.log("success");
        itemsFromFs.docs.forEach((item) =>
          signals.push({ id: item.id, ...item.data(), category: "forex" })
        );
        dispatch(getSignals(signals));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllSignalsSynthetics = () => {
  return (dispatch) => {
    const signals = [];
    dispatch(isLoading(true));
    db.collection("signals")
      .doc("binaries")
      .collection("items")
      .get()
      .then(async (itemsFromFs) => {
        console.log("success");
        itemsFromFs.docs.forEach((item) =>
          signals.push({ id: item.id, ...item.data(), category: "binaries" })
        );
        dispatch(getBinaries(signals));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const editSignal = async (data, category) => {
  console.log(data.id, category);
  try {
    await db
      .collection("signals")
      .doc(category)
      .collection("items")
      .doc(data.id)
      .set(
        {
          alias: data.alias ?? "",
          updated: data.updated ?? "",
          description: data.description ?? "",
          type: data.type ?? "",
          currency: data.currency ?? "",
          entryPrice: data.entryPrice ?? "",
          eta: data.eta ?? "0",
          stopLoss: data.stopLoss ?? "",
          tp1: data.tp1 ?? "",
          tp2: data.tp2 ?? "",
          tp3: data.tp3 ?? "",
          tp4: data.tp4 ?? "",
          tp5: data.tp4 ?? "",
          tp6: data.tp4 ?? "",
          tp1Reached: data.tp1Reached ,
          tp2Reached: data.tp2Reached ,
          tp3Reached: data.tp3Reached ,
          tp4Reached: data.tp4Reached ,
          tp5Reached: data.tp5Reached ,
          tp6Reached: data.tp6Reached ,
          picture: data.picture ?? "",
          status: data.status ?? "",
          cdate: new Date(),
          tier: data.tier ?? "",
        },
        { merge: true }
      );

    console.log("signal updated successfully!");
  } catch (error) {
    console.error("Error updating signal:", error);
    return null;
  }
};
export const addSignal = async (data, category) => {
  try {
    await db.collection("signals").doc(category).collection("items").add(data);

    console.log("Signal added successfully!");
  } catch (error) {
    console.error("Error adding signal:", error);
    return null;
  }
};
export const deleteFSignal = async (itemId, category) => {
  try {
    await db
      .collection("signals")
      .doc(category)
      .collection("items")
      .doc(itemId)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteNotification = async (itemId) => {
  try {
    await db
      .collection("notifications")

      .doc(itemId)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const putSignal = async (data, token) => {
  const url = ADMIN_API_URL + "/signals/forex";

  const body = {
    id: data.signalId,
    status: data.status,
    stopLoss: data.stopLoss,
  };

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
// export const putSignal= (signal) =>{
//   console.log(signal);
//   const url = ADMIN_API_URL + "/signals/forex";
//   const body = {
//     id: signal.signalId,
//     status: signal.status,
//     color: signal.color,
//     stopLoss: signal.stopLoss,
//   };
//   return this.http.put(url, body);
// }

export const postPushNotificationAll = async (data, token) => {
  const url = API_URL + "/notifications/pushChunks";

  const MAX_TOKENS_PER_MESSAGE = 500;
  const chunks = [];
  for (let i = 0; i < data.pushTokens.length; i += MAX_TOKENS_PER_MESSAGE) {
    chunks.push(data.pushTokens.slice(i, i + MAX_TOKENS_PER_MESSAGE));
  }

  const body = {
    title: data.title,
    body: data.text,
    type: data.type,
    audience: data.audience,
    pushTokens: chunks,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    console.log("response", response, "res", res);
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSignals = (signals) => ({
  type: SET_SIGNALS,
  payload: {
    signals,
  },
});
export const getBinaries = (binaries) => ({
  type: SET_BINARIES,
  payload: {
    binaries,
  },
});
export const isLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
