import jwtDecode from "jwt-decode";

import React, { useCallback, useState, useEffect } from "react";

import dayjs from "dayjs";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  checkIfUserExists,
  createAdmin,
  editUser,
} from "../../store/actions/users";
import { useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { idTypes, memberOptions } from "../../utils/formData";
import { MdNotificationImportant } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers";
import Colors from "../../../assets/styles/Colors";
import { fsTimestampCreador } from "../../../firebase/firebase-config";
import { getExpiredStatus, refreshToken } from "../../store/actions/auth";
import { useSelector } from "react-redux";

const MySwal = withReactContent(Swal);

const AdminsEditModal = ({ onClose, open, item, refresh }) => {
  const theme = useTheme();
  const { idToken } = useSelector((state) => state.auth);
  const [expired, setexpired] = useState(false);
  const admins = [
    {
      value: "moderator",
      label: "Moderador",
    },

    {
      value: "admin",
      label: "Administrador",
    },
    {
      value: "superadmin",
      label: "Superadmin",
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };

  const validationSchema = yup.object({
    email: yup
      .string("Ingresa un correo electrónico")
      .email("Ingresa un correo electrónico válido")
      .test(
        "is-lowercase",
        "El email debe estar en minúsculas",
        (value) => value === value.toLowerCase()
      )
      .required("Campo Obligatorio"),
    adminClaim: yup
      .string("Seleccione el rol de administrador")
      .oneOf(["moderator", "admin", "superadmin"], "Seleccione un rol válido")
      .required("Campo Obligatorio"),
  });
  const formik = useFormik({
    initialValues: {
      email: item.email ?? "",
      adminClaim: item.adminClaim ?? "moderator",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "Confirmar",
      text: "Esta seguro que quiere editar este administrador?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      const adminData = {
        email: values.email,
        adminClaim: values.adminClaim,
      };

      if (result.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const userExists = await checkIfUserExists(values.email);

          if (!userExists) {
            MySwal.fire("Usuario no existe", "", "error");
            onClose();
            return;
          }

          const token = isValidToken(idToken) ? idToken : await refreshToken();
          const res = await createAdmin(adminData, token);

          if (res.ok) {
            loadingSwal.close();
            MySwal.fire(
              `Administrador editado con el correo  ${values.email}`,
              "",
              "success"
            );
          } else if (res.error.code === 11) {
            MySwal.fire("Email already exists", "", "error");
          } else {
            console.error("Error creating user:", res.error);
            MySwal.fire("Error creating user", "", "error");
          }
        } catch (error) {
          console.error("Error:", error);
          MySwal.fire("Error occurred", "", "error");
        }
        loadingSwal.close();
        refresh();
        onClose();
      } else if (result.isDenied) {
        onClose();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,
          marginTop: 100,
          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
      sx={{ zIndex: 1251, width: "100%" }}
      fullWidth={true}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Usuario
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={12} sm={12}>
            <Box sx={styles.cardStyle} className=" add-media-card mb-4 ">
              <form onSubmit={formik.handleSubmit} className="m-auto  w-100">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="email"
                    name="email"
                    type="text"
                    label="Correo Electronico"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    fullWidth
                    label="Seleccione"
                    onClick={(e) => e.preventDefault()}
                    id="adminClaim"
                    name="adminClaim"
                    select
                    value={formik.values.adminClaim}
                    onChange={formik.handleChange}
                    SelectProps={{
                      MenuProps: {
                        style: {
                          maxWidth: 0,
                          maxHeight: 300,

                          getContentAnchorEl: () => null,
                        },
                        disableScrollLock: true,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdNotificationImportant />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  >
                    {admins.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: 4,
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AdminsEditModal;
