import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Dragndrop from "../components/dragndrop/Dragndrop";
import Preview from "./dragndrop/Preview";
import { MdHome } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { uploadHomeItems } from "../store/actions/items";
import { storage } from "../../firebase/firebase-config";
import { homeItemsMain } from "../utils/formData";
import { getExpiredStatus } from "../store/actions/auth";
const MySwal = withReactContent(Swal);

const AddItems = ({ refresh }) => {
  const [value, setValue] = useState("recommended");
  const [files, setFiles] = useState([]);
  const [expired, setexpired] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  // useEffect(() => {
  //   // Clear added items when the component is loaded
  //   setFiles([]); // Clear the added files array
  // }, []);

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach((file) => URL.revokeObjectURL(file.preview));
  // }, [files]);
  const validationSchema = yup.object({
    name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
    description: yup
      .string("Ingrese una descripción")
      .required("Campo Obligatorio"),
    // imageURL: yup.string("Ingrese una imagen").required("Campo Obligatorio"),
  });
  const formik = useFormik({
    initialValues: {
      contentType: "recommended",
      name: "",
      category: "",
      imageURL: "",
      clickMe: "",
      streamURL: "",
      description: "",
      files: null,
      orderNumber: 0,
    },
    enableReinitialize: true,
    // validationSchema: validationSchema,
    validateOnMount: true,

    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        if (files.length > 0) {
          const file = files[0];
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `images/items/home/${value}/${file.name}`
          );
          await imageRef.put(file);

          const imageURL = await imageRef.getDownloadURL();

          values.imageURL = imageURL;
        }

        const recommendedData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "",
          orderNumber: values.orderNumber,
          clickMe: values.clickMe,
          tags: ["recommended"],
        };
        const eventsData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "events",
          orderNumber: values.orderNumber,
          clickMe: values.clickMe,
          tags: ["home"],
        };
        const successData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          orderNumber: values.orderNumber,
          tags: ["success"],
        };
        try {
          if (value === "recommended") {
            await uploadHomeItems(recommendedData);
          } else if (value === "events") {
            await uploadHomeItems(eventsData);
          } else if (value === "success") {
            await uploadHomeItems(successData);
          }
          loadingSwal.close();
          MySwal.fire("Item Guardado!", "", "success");
          removeAll();
          formik.resetForm(); // Clear the files after submission
          refresh();
        } catch (error) {
          console.log("add error", error);
          loadingSwal.close();
          MySwal.fire("Hubo un error al guardar el item", "", "error");
        }
      }
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [files]
  );
  const removeAll = () => {
    setFiles([]);
  };

  const selectedFields = useMemo(() => {
    const selectedItem = homeItemsMain.find((item) => item.value === value);
    return selectedItem ? selectedItem.fields : [];
  }, [value]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        lg={6}
        sm={8}
        xs={12}
        sx={styles.cardStyle}
        className=" add-media-card mb-4 "
      >
        <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
          <Typography
            sx={styles.cardTitleStyle}
            variant="h6"
            className="center"
          >
            Agregar Items
          </Typography>
          <Box sx={styles.innerCardStyle}>
            <TextField
              fullWidth
              id="contentType"
              name="contentType"
              select
              label="Seleccione"
              value={value}
              onChange={handleChange}
              helperText="Escoja la sección que desea cambiar"
              SelectProps={{
                MenuProps: {
                  style: {
                    maxWidth: 0,
                    maxHeight: 300,
                    position: "absolute",
                    getContentAnchorEl: () => null,
                  },
                  disableScrollLock: true,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdHome />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            >
              {homeItemsMain.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {selectedFields.includes("name") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label="Nombre"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            )}

            {selectedFields.includes("description") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                label="Descripción"
                type="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            )}

            {selectedFields.includes("details") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="details"
                name="details"
                label="Detalles"
                type="details"
                value={formik.values.details}
                onChange={formik.handleChange}
                error={formik.touched.details && Boolean(formik.errors.details)}
                helperText={formik.touched.details && formik.errors.details}
              />
            )}

            {selectedFields.includes("streamURL") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="streamURL"
                name="streamURL"
                label="URL de Transmisión"
                value={formik.values.streamURL}
                onChange={formik.handleChange}
                error={
                  formik.touched.streamURL && Boolean(formik.errors.streamURL)
                }
                helperText={formik.touched.streamURL && formik.errors.streamURL}
              />
            )}
            {selectedFields.includes("clickMe") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="clickMe"
                name="clickMe"
                label="Enlace para compartir"
                value={formik.values.clickMe}
                onChange={formik.handleChange}
                error={
                  formik.touched.streamURL && Boolean(formik.errors.streamURL)
                }
                helperText={formik.touched.streamURL && formik.errors.streamURL}
              />
            )}
            {selectedFields.includes("videoURL") && (
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                id="videoURL"
                name="videoURL"
                label="URL de Video"
                value={formik.values.videoURL}
                onChange={formik.handleChange}
                error={
                  formik.touched.videoURL && Boolean(formik.errors.videoURL)
                }
                helperText={formik.touched.videoURL && formik.errors.videoURL}
              />
            )}
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              type="number"
              id="orderNumber"
              name="orderNumber"
              label="Numero Orden"
              value={formik.values.orderNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.orderNumber && Boolean(formik.errors.orderNumber)
              }
              helperText={
                formik.touched.orderNumber && formik.errors.orderNumber
              }
            />
            <Box item md={6} sx={styles.uploadBoxStyle}>
              {files.length === 0 ? (
                <div className=" center m-auto">
                  <Dragndrop onDrop={onDrop} accept={"image/*"} maxFiles={1} />
                </div>
              ) : (
                <Preview files={files} removeAll={removeAll} />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                mt: 4,
                marginBottom: "5px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!formik.isValid && files.length === 0}
              >
                Crear
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddItems;
