import React, { useCallback, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { storage } from "../../../firebase/firebase-config";
import { MdHome } from "react-icons/md";
import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";
import {
  editFlyer,
  editTraining,
  uploadHomeItems,
} from "../../store/actions/items";
import { useTheme } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Close } from "@mui/icons-material";
import { homeItemsMain, servicesOptions } from "../../utils/formData";
import { getExpiredStatus } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

const ServicesEditModal = ({ onClose, open, data, refresh, category }) => {
  const theme = useTheme();
  const [value, setValue] = useState("trading");
  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");
  const [files, setFiles] = useState([]);
  const [expired, setexpired] = useState(false);

  useEffect(() => {
    if (data.imageURL) {
      setOgImage(data.imageURL);
    }
  }, []);

  useEffect(() => {
    // Remove the trailing "&" from the videoURL if it exists
    const cleanedVideoURL = data.videoURL
      ? data.videoURL.replace(/&$/, "")
      : "";

    formik.setValues({
      name: data.name ?? "",
      category: data.category ?? "",
      imageURL: data.imageURL ?? "",
      videoURL: cleanedVideoURL ?? "",
      section: data.section ?? "",
      streamURL: data.streamURL ?? "",
      clickMe: data.clickMe ?? "",
      isMembersOnly: data.isMembersOnly ?? "",
      isGoldPlan: data.isGoldPlan ?? "",
      description: data.description ?? "",
      files: null,
      orderNumber: data.orderNumber ?? 0,
    });
  }, [data]);
  const formik = useFormik({
    initialValues: {
      name: data.name ?? "",
      category: data.category ?? "",
      imageURL: data.imageURL ?? "",
      videoURL: data.videoURL ?? "",
      section: data.section ?? "",
      streamURL: data.streamURL ?? "",
      clickMe: data.clickMe ?? "",
      isMembersOnly: data.isMembersOnly ?? "",
      isGoldPlan: data.isGoldPlan ?? "",
      description: data.description ?? "",
      files: null,
      orderNumber: data.orderNumber ?? "",
    },
    enableReinitialize: true,
    // validationSchema: yup.object({
    //   name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
    //   description: yup
    //     .string("Ingrese una descripción")
    //     .required("Campo Obligatorio"),
    // }),

    onSubmit: (values) => {
      SubmitForm(values);

      console.log({
        files: values.files.map((file) => ({
          fileName: file.name,
          type: file.type,
          size: `${file.size} bytes`,
        })),
      });
    },
  });
  const handleRemoveImg = () => {
    formik.setFieldValue("imageURL", "");
    setOgImage(""); // Set the imageURL field to an empty value
  };

  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "Guardando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      // Display confirmation dialog
      const result = await MySwal.fire({
        title: "¿Confirmar?",
        text: "¿Está seguro que quiere editar este item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Editar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        MySwal.fire("Guardado!", "", "success");

        if (images.length > 0) {
          const file = images[0];
          const storageRef = storage.ref();

          // Upload the new image inside the course folder
          const imageRef = storageRef.child(
            `images/items/home/${value}/${file.name}`
          );
          await imageRef.put(file);
          values.imageURL = await imageRef.getDownloadURL();
        } else {
          // If no new images are selected, keep the original imageURL
          values.imageURL = ogImage || "https://picsum.photos/200/300";
        }
        const updatedData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "",
          clickMe: values.clickMe,

          section: values.section,
          isMembersOnly: false,
          isGoldPlan: false,
          videoURL: values.videoURL.includes("youtube")
            ? values.videoURL + "&"
            : values.videoURL,
          orderNumber: values.orderNumber,
        };
        try {
          await editTraining(updatedData, "services", data.id);
          removeAll();
          formik.resetForm();
          refresh();
        } catch (error) {
          console.log("edit error", error);
          MySwal.fire("Hubo un error al guardar el item", "", "error");
        }

        refresh();
        onClose();
      } else if (result.isDenied) {
        MySwal.fire("Cambios no guardados", "", "info");
      }
    } catch (error) {
      // Show error message
      MySwal.fire({
        title: "Error al guardar",
        text: "Ha ocurrido un error al guardar los cambios.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  //Dropzone

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Video
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12} className="mb-4">
            <Box sx={[styles.cardStyle, { width: 500 }]}>
              <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="section"
                    name="section"
                    select
                    label="Sección"
                    onChange={formik.handleChange}
                    value={formik.values.section} // Use formik value
                  >
                    {servicesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="description"
                    name="description"
                    label="Descripción"
                    type="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="clickMe"
                    name="clickMe"
                    label="Enlace"
                    value={formik.values.clickMe}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.streamURL &&
                      Boolean(formik.errors.streamURL)
                    }
                    helperText={
                      formik.touched.streamURL && formik.errors.streamURL
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="streamURL"
                    name="streamURL"
                    label="URL de transmisión"
                    value={formik.values.streamURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.streamURL &&
                      Boolean(formik.errors.streamURL)
                    }
                    helperText={
                      formik.touched.streamURL && formik.errors.streamURL
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="videoURL"
                    name="videoURL"
                    label="URL de Video"
                    value={formik.values.videoURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.videoURL &&
                      Boolean(formik.errors.streamURL)
                    }
                    helperText={
                      formik.touched.videoURL && formik.errors.videoURL
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    type="number"
                    id="orderNumber"
                    name="orderNumber"
                    label="Orden"
                    value={formik.values.orderNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.orderNumber &&
                      Boolean(formik.errors.orderNumber)
                    }
                    helperText={
                      formik.touched.orderNumber && formik.errors.orderNumber
                    }
                  />
                  {ogImage === "" ? (
                    <Box item md={6} sx={styles.uploadBoxStyle}>
                      {images.length === 0 ? (
                        <div className=" center m-auto">
                          <Dragndrop
                            onDrop={onDrop}
                            accept={"image/*"}
                            maxFiles={1}
                          />
                        </div>
                      ) : (
                        <Preview files={images} removeAll={removeAll} />
                      )}
                    </Box>
                  ) : (
                    <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={[
                            styles.deleteIcon,
                            { background: theme.palette.error.main },
                          ]}
                          onClick={() => handleRemoveImg()}
                        >
                          <HighlightOffIcon />
                        </Box>
                        <img
                          src={data.imageURL}
                          alt="Original"
                          style={{
                            width: "auto",
                            maxHeight: "125px",
                            borderRadius: "18px",
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ServicesEditModal;
