import React, { useCallback, useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { storage } from "../../../firebase/firebase-config";

import Dragndrop from "../dragndrop/Dragndrop";
import Preview from "../dragndrop/Preview";
import { editFlyer, editTraining } from "../../store/actions/items";
import { useTheme } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Close } from "@mui/icons-material";
import {
  bannerType,
  homeItemsMain,
  membershipOptions,
} from "../../utils/formData";
import { getExpiredStatus } from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

const CoverEditModal = ({ onClose, open, data, refresh, category }) => {
  const theme = useTheme();

  const [images, setImages] = useState([]);
  const [ogImage, setOgImage] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState("public");
  const [expired, setexpired] = useState(false);
  useEffect(() => {
    if (data.imageURL) {
      setOgImage(data.imageURL);
    }
  }, []);

  const cleanedVideoURL = data.videoURL ? data.videoURL.replace(/&$/, "") : "";

  const formik = useFormik({
    initialValues: {
      contentType: category,
      name: data.name ?? "",
      category: data.category ?? "",
      clickMe: data.clickMe ?? "",
      streamURL: data.streamURL ?? "",
      isMembersOnly: data.isMembersOnly ?? false,
      isGoldPlan: data.isGoldPlan ?? false,
      description: data.description ?? "",
      videoURL: cleanedVideoURL ?? "",
      imageURL: data.imageURL ?? "",
      orderNumber: data.orderNumber ?? 0,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string("Ingrese el nombre").required("Campo Obligatorio"),
      description: yup
        .string("Ingrese una descripción")
        .required("Campo Obligatorio"),
    }),

    onSubmit: (values) => {
      SubmitForm(values);

      // console.log({
      //   files: values.files.map((file) => ({
      //     fileName: file.name,
      //     type: file.type,
      //     size: `${file.size} bytes`,
      //   })),
      // });
    },
  });

  const handleMembershipChange = (event) => {
    setSelectedMembership(event.target.value);
  };

  const handleRemoveImg = () => {
    formik.setFieldValue("imageURL", "");
    setOgImage(""); // Set the imageURL field to an empty value
  };

  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);

  const SubmitForm = async (values) => {
    // Show loading indicator

    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "Guardando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      // Display confirmation dialog
      const result = await MySwal.fire({
        title: "¿Confirmar?",
        text: "¿Está seguro que quiere editar este item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Agregar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        if (images.length > 0) {
          const file = images[0];
          const storageRef = storage.ref();

          // Upload the new image inside the course folder
          const imageRef = storageRef.child(
            `images/items/academy/banners/${file.name}`
          );
          await imageRef.put(file);
          values.imageURL = await imageRef.getDownloadURL();
        } else {
          // If no new images are selected, keep the original imageURL
          values.imageURL = ogImage;
        }
        let members = false;
        let goldPlan = false;
        if (selectedMembership === "public") {
          members = false;
          goldPlan = false;
        } else if (selectedMembership === "members") {
          members = true;
          goldPlan = false;
        } else if (selectedMembership === "goldPlan") {
          members = false;
          goldPlan = true; // Set goldPlan to true for the "goldPlan" membership
        }
        const updatedData = {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          clickMe: values.clickMe,
          isMembersOnly: members,
          isGoldPlan: goldPlan,
          videoURL: values.videoURL + "&",
          cdate: new Date(),
          orderNumber: values.orderNumber,
        };

        try {
          if (contentType === "education:news") {
            await editTraining(updatedData, "education:news", data.id);
          } else {
            await editTraining(updatedData, "trading:news", data.id);
          }

          MySwal.fire("Item Guardado!", "", "success");
          refresh();
          formik.resetForm();
          removeAll(); // Clear the files after submission
          onClose();
        } catch (error) {
          console.log(error);
          MySwal.fire("Hubo un error al guardar el item", "", "error");
        }
      } else if (result.isDenied) {
        MySwal.fire("Cambios no guardados", "", "info");
      }
    } catch (error) {
      // Show error message
      console.log(error);
      MySwal.fire({
        title: "Error al guardar",
        text: "Ha ocurrido un error al guardar los cambios.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview:
              typeof file === "string" ? file : URL.createObjectURL(file),
          })
        )
      );
    },
    [images]
  );
  const removeAll = () => {
    setImages([]);
  };
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.secondary.secondary,

          backgroundImage: "none",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        Editar Banner
        <Box sx={[styles.closeButton]} onClick={onClose}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={8} sm={12} className="mb-4">
            <Box sx={[styles.cardStyle, { width: 500 }]}>
              <form onSubmit={formik.handleSubmit} className="m-auto p-2 w-75">
                <Box sx={styles.innerCardStyle}>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="contentType"
                    name="contentType"
                    select
                    label="Tipo"
                    onChange={formik.handleChange}
                    value={formik.values.contentType} // Use formik value
                  >
                    {bannerType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="description"
                    name="description"
                    label="Descripción"
                    type="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />

                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="videoURL"
                    name="videoURL"
                    label="URL de Video"
                    value={formik.values.videoURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.videoURL && Boolean(formik.errors.videoURL)
                    }
                    helperText={
                      formik.touched.videoURL && formik.errors.videoURL
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="streamURL"
                    name="streamURL"
                    label="URL de transmisión"
                    value={formik.values.streamURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.streamURL &&
                      Boolean(formik.errors.streamURL)
                    }
                    helperText={
                      formik.touched.streamURL && formik.errors.streamURL
                    }
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="clickMe"
                    name="clickMe"
                    label="URL de CTA"
                    value={formik.values.clickMe}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.clickMe && Boolean(formik.errors.clickMe)
                    }
                    helperText={formik.touched.clickMe && formik.errors.clickMe}
                  />
                  <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    id="membership"
                    name="membership"
                    select
                    label="Membresía"
                    value={selectedMembership}
                    onChange={handleMembershipChange}
                  >
                    {membershipOptions.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>

                  {ogImage === "" ? (
                    <Box item md={6} sx={styles.uploadBoxStyle}>
                      {images.length === 0 ? (
                        <div className=" center m-auto">
                          <Dragndrop
                            onDrop={onDrop}
                            accept={"image/*"}
                            maxFiles={1}
                          />
                        </div>
                      ) : (
                        <Preview files={images} removeAll={removeAll} />
                      )}
                    </Box>
                  ) : (
                    <Box item md={6} sx={styles.uploadBoxStyle} mt={2}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={[
                            styles.deleteIcon,
                            { background: theme.palette.error.main },
                          ]}
                          onClick={() => handleRemoveImg()}
                        >
                          <HighlightOffIcon />
                        </Box>
                        <img
                          src={data.imageURL}
                          alt="Original"
                          style={{
                            width: "auto",
                            maxHeight: "125px",
                            borderRadius: "18px",
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      className="w-100"
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoverEditModal;
