import { types } from "../types/types";
import {
  SET_ITEMS,
  SET_COURSES,
  SET_IS_LOADING,
  GET_EVENTS,
  GET_RECOMMENDED,
  GET_SUCCESS,
  GET_SERVICES,
  GET_ACADEMY_NEWS,
  GET_TRADING_NEWS,
  GET_CATEGORY_ITEMS,
  GET_WOMANOFPOWER,
  GET_PERSONALDEV,
  GET_MODULEFOUR,
  GET_FOREX,
  GET_BINARIES,
  GET_CRYPTOS,
  GET_SUPPORT_PHONE,
  GET_SYNTHETICS,
  GET_ONEMINDSET,
} from "../actions/items";

const initialState = {
  items: null,
  category: "all",
  isLoading: false,
  courses: null,
  searchValue: "",
  events: null,
  recommended: null,
  success: null,
  services: null,
  academyNews: [],
  tradingNews: [],
  marketingNews: null,
  onemindset: null,
  womanofpower: null,
  personalDevelopment: null,
  moduleFour: null,
  forex: null,
  binaries: null,
  cryptos: null,
  newDist: null,
  actionPlan: null,
  digitalMarketing: null,
  supportPhone: null,
  categoryItems: null,
  synthetics: null,
  onemindset: null,
};

export const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload.items,
        // displayName: action.payload.displayName,
      };
    case SET_COURSES:
      return {
        ...state,
        courses: action.payload.courses,
        // displayName: action.payload.displayName,
      };
    case types.changeCategory:
      return {
        ...state,
        category: action.payload.category,
        // displayName: action.payload.displayName,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        // displayName: action.payload.displayName,
      };

    case GET_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case GET_RECOMMENDED:
      return {
        ...state,
        recommended: action.recommended,
      };
    case GET_SUCCESS:
      return {
        ...state,
        success: action.success,
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.services,
      };
    case GET_ACADEMY_NEWS:
      return {
        ...state,
        academyNews: action.academyNews,
      };
    case GET_TRADING_NEWS:
      return {
        ...state,
        tradingNews: action.tradingNews,
      };

    case GET_ONEMINDSET:
      return {
        ...state,
        onemindset: action.onemindset,
      };
    case GET_CATEGORY_ITEMS:
      return {
        ...state,
        categoryItems: action.categoryItems,
      };
    case GET_WOMANOFPOWER:
      return {
        ...state,
        womanOfPower: action.womanOfPower,
      };
    case GET_PERSONALDEV:
      return {
        ...state,
        personalDev: action.personalDev,
      };
    case GET_MODULEFOUR:
      return {
        ...state,
        moduleFour: action.moduleFour,
      };
    case GET_FOREX:
      return {
        ...state,
        forex: action.forex,
      };
    case GET_BINARIES:
      return {
        ...state,
        binaries: action.binaries,
      };
    case GET_SYNTHETICS:
      return {
        ...state,
        synthetics: action.synthetics,
      };
    case GET_CRYPTOS:
      return {
        ...state,
        cryptos: action.cryptos,
      };

    case GET_SUPPORT_PHONE:
      return {
        ...state,
        supportPhone: action.supportPhone,
      };

    case types.searchValue:
      return {
        ...state,
        searchValue: action.payload.searchValue,
        // displayName: action.payload.displayName,
      };
    default:
      return state;
  }
};
