import jwtDecode from "jwt-decode";
import "sweetalert2/dist/sweetalert2.min.css";
import React, { useState, useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../assets/styles/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  checkIfUserExists,
  createAdmin,
  createUser,
} from "../store/actions/users";

import { useSelector } from "react-redux";
import { getExpiredStatus, refreshToken } from "../store/actions/auth";

import { MdNotificationImportant } from "react-icons/md";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { idTypes, memberOptions } from "../utils/formData";
import { DatePicker } from "@mui/x-date-pickers";
import Colors from "../../assets/styles/Colors";
import { fsTimestampCreador } from "../../firebase/firebase-config";

const MySwal = withReactContent(Swal);

const AddUsers = ({ refresh }) => {
  const { idToken } = useSelector((state) => state.auth);

  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [expired, setexpired] = useState(false);
  const validationSchema = yup.object({
    name: yup.string().required("Campo Obligatorio"),
    email: yup
      .string()
      .email("Ingrese un correo electrónico válido")
      .required("Campo Obligatorio"),
    tier: yup.string().required("Campo Obligatorio"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      lastname2: "",
      phone: "",
      country: "",
      city: "",
      address: "",
      birthday: parsedDate,
      idType: "01",
      personalId: "",
      isActive: false,
      isGoldPlan: false,
      tier: 2,
    },

    // enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SubmitForm(values);
    },
  });
  const tiers = [
    {
      value: 1,
      label: "Nivel Básico",
    },
    {
      value: 2,
      label: "Nivel Intermedio",
    },
    {
      value: 3,
      label: "Nivel Avanzado",
    },
  ];
  const admins = [
    {
      value: "manager",
      label: "Gestor",
    },
    {
      value: "assistant",
      label: "Asistente",
    },

    {
      value: "admin",
      label: "Administrador",
    },
    {
      value: "superadmin",
      label: "Superadmin",
    },
  ];
  const isValidToken = (token) => {
    if (!token) {
      return false; // If token is missing or undefined, it is not valid
    }
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime < decodedToken.exp;
  };
  const getStatusE = useCallback(() => {
    const unsubscribe = getExpiredStatus((statusExpired) => {
      console.log(statusExpired);
      setexpired(statusExpired);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getStatusE();
  }, [getStatusE]);
  const resetForm = () => {
    formik.resetForm();
  };
  const SubmitForm = async (values) => {
    const timestampBirthday = fsTimestampCreador.fromDate(
      new Date(formik.values.birthday)
    );

    if (expired) {
      MySwal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Contacte a Soporte",
      });
      return;
    }

    MySwal.fire({
      title: "¿Confirm?",
      text: "¿Está seguro que quiere agregar este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "warning",
          title: "Guardando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          showConfirmButton: false,
        });

        let active = false;
        let goldPlan = false;

        const userData = {
          email: values.email,
          name: values.name || "",
          lastname: values.lastname || "",
          lastname2: values.lastname2 ?? "",
          phone: values.phone || "",
          country: values.country || "",
          city: values.city || "",
          tier: values.tier,
        };

        const userExists = await checkIfUserExists(values.email);
        if (userExists) {
          MySwal.fire(
            "Ya existe un usuario con el mismo correo electrónico",
            "",
            "error"
          );
        } else {
          try {
            if (isValidToken(idToken)) {
              const res = await createUser(userData, idToken);
              if (res.ok) {
                MySwal.fire(
                  `Usuario ${
                    values.name + " " + values.lastname
                  } creado con el correo ${values.email}  `,
                  "",
                  "success"
                );
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            } else {
              const newToken = await refreshToken();
              const res = await createUser(userData, newToken);
              if (res.ok) {
                MySwal.fire(
                  `Usuario ${
                    values.name + " " + values.lastname
                  } creado con el correo ${values.email} `,
                  "",
                  "success"
                );
              } else if (res.error.code === 11) {
                MySwal.fire("El correo ya existe", "", "error");
              }
            }
          } catch (error) {
            console.error("Error creating user:", error);

            MySwal.fire("Hubo un error al guardar el usuario", "", "error");
          }
        }

        refresh();
        resetForm();
      } else if (result.dismiss === "cancel") {
        MySwal.fire("Cambios no guardados", "", "info");
        refresh();
      }
    });
  };
  const handleMembershipChange = (event) => {
    setSelectedMembership(event.target.value);
  };
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        lg={6}
        sm={10}
        xs={12}
        sx={styles.cardStyle}
        className=" mb-4 "
      >
        <form onSubmit={formik.handleSubmit} className="m-auto p-2 ">
          <Typography
            sx={styles.cardTitleStyle}
            variant="h6"
            className="center"
          >
            Agregar Usuario
          </Typography>
          <Box sx={styles.innerCardStyle}>
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="email"
              name="email"
              type="email"
              label="Correo Electrónico"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              type="text"
              label="Nombre"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="lastname"
              name="lastname"
              type="text"
              label="Apellido"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
            <TextField
              margin="dense"
              variant="standard"
              fullWidth
              id="lastname2"
              name="lastname2"
              type="text"
              label="Segundo Apellido"
              value={formik.values.lastname2}
              onChange={formik.handleChange}
              error={
                formik.touched.lastname2 && Boolean(formik.errors.lastname2)
              }
              helperText={formik.touched.lastname2 && formik.errors.lastname2}
            />
            <Grid
              item
              md={12}
              container
              justifyContent={"space-between"}
              mt={2}
              columnGap={2}
            >
              <Grid item md={5.5} xs={12}>
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="phone"
                  name="phone"
                  type="phone"
                  label="Telêfono"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item md={5.5} xs={12}>
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="country"
                  name="country"
                  type="country"
                  label="País"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              container
              justifyContent={"space-between"}
              mt={{ xs: 0, sm: 2 }}
              columnGap={0.5}
            >
              <Grid item md={5.5} xs={12}>
                <TextField
                  margin="dense"
                  variant="standard"
                  fullWidth
                  id="city"
                  name="city"
                  type="city"
                  label="Ciudad"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={12}
              container
              justifyContent={"space-between"}
              mt={{ xs: 0, sm: 2 }}
              columnGap={2}
            ></Grid>

            <Grid
              item
              md={12}
              container
              justifyContent={"space-between"}
              mt={2}
              columnGap={2}
            >
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Membresía"
                  id="tier"
                  name="tier"
                  onClick={(e) => e.preventDefault()}
                  select
                  value={formik.values.tier}
                  onChange={formik.handleChange}
                  autoFocus={false}
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxWidth: 0,
                        maxHeight: 300,

                        getContentAnchorEl: () => null,
                      },
                      disableScrollLock: true,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdNotificationImportant />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                >
                  {memberOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button
                className="w-50"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!formik.isValid}
              >
                Crear
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddUsers;
