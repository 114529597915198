import { auth, db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL } from "../../variables/globals";
import { types } from "../types/types";

export const refreshToken = async () => {
  const token = await auth.currentUser.getIdToken(true);

  return token;
};

export const login = (email, password, setloading) => {
  return async (dispatch) => {
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);
      const idToken = await response.user.getIdToken(true);
      const adminInfo = await getAdminStatus(email);
      const metadata = await response.user.getIdTokenResult();
      if (
        metadata.claims.superadmin ||
        metadata.claims.admin ||
        metadata.claims.almighty ||
        metadata.claims.moderator
      ) {
        dispatch(
          signInWithEmail(response.user.toJSON(), metadata.claims, idToken)
        );
      } else {
        sessionStorage.removeItem("user");
        dispatch(setAuthError("Invalid email address"));
        dispatch(logoutUser());
      }
    } catch (error) {
      dispatch(setAuthError(error.code));
      setloading(false);
      console.error(error);
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await auth.signOut();
      dispatch(logout());
    } catch (error) {
      console.error(error);
    }
  };
};
export const getAdminStatus = async (email) => {
  try {
    const adminQuery = await db
      .collection("admins")
      .where("email", "==", email)
      .get();

    if (adminQuery.empty) {
      // No admin found with the given email
      return { adminClaim: undefined };
    }

    const adminDoc = adminQuery.docs[0];
    const admin = adminDoc.data();

    return { adminClaim: admin ? admin.adminClaim : undefined };
  } catch (error) {
    console.error("Error fetching admin status:", error);
    throw error;
  }
};

export const signInWithEmail = (user, claims, idToken) => ({
  type: types.login,
  payload: {
    user,
    loggedIn: true,
    isAlmighty: claims.almighty,
    isSuperAdmin: claims.superadmin,
    isAdmin: claims.admin,
    isModerator: claims.moderator,
    idToken: idToken,
  },
});

export const setAuthError = (errorCode) => {
  let errorMsg = "";

  switch (errorCode) {
    case "auth/user-not-found":
      errorMsg = "Usuario no encontrado. Verifique sus credenciales.";
      break;
    case "auth/wrong-password":
      errorMsg = "Contraseña incorrecta. Verifique sus credenciales.";
      break;
    // Add more error codes and corresponding messages as needed
    default:
      errorMsg = "Error en el inicio de sesión. Verifique sus credenciales.";
  }

  return {
    type: types.errorMsg,
    payload: {
      errorCode: errorCode,
      errorMsg: errorMsg,
    },
  };
};

export const getExpiredStatus = (updateStatusCallback) => {
  const unsubscribe = db
    .collection("businesses")
    .doc("root")
    .onSnapshot((snapshot) => {
      // Accessing the "paymentExpired" field from the retrieved document
      const statusExpired = snapshot.data().paymentExpired;
      updateStatusCallback(statusExpired);
    });

  // Returning the unsubscribe function to allow cleanup
  return unsubscribe;
};

export const setIsLoadingAuth = (isLoading) => ({
  type: types.isLoadingAuth,
  payload: {
    isLoadingAuth: isLoading,
  },
});

export const logout = () => ({
  type: types.logout,
  payload: {},
});

export const ReCaptcha = (token) => {
  // https://www.google.com/recaptcha/api/siteverify
};
